import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import BigBay, { toCellCode } from './bayplanning/BigBay';
import ViewCallSettingsModal from './ViewCallSettingsModal';
import ImportBaplieModal from './ImportBaplieModal';
import BaysPrintView from './BaysPrintView';
import ReactToPrint from 'react-to-print';
import ExportBaplieModal from './ExportBaplieModal';
import EditCallInfoModal from './EditCallInfoModal';
import SelectableMiniBay from './SelectableMiniBay';
import CargoList from './CargoList';
import ReactTooltip from 'react-tooltip';
import usePersistentState from '../../../hooks/usePersistentState';
import ColorsLegend from './ColorsLegend';
import ThreeDimensionalBayPlan from './ThreeDimensionalBayPlan';
import { prepContainerNumber } from './CreateContainerModal';
import WarningModal from "./WarningModal";
import { Button, LinkButton, LoadingIndicator, LoadingOverlay } from '@atrocit/scl';
import EventLog from './EventLog';
import UserContext from '../../../context/UserContext';
import BaysOverview from './BaysOverview';
import VesselFinderView from './vesselfinder/VesselFinderView';
import MergeModal from './MergeModal';
import generateWarnings from './WarningGenerator';
import { DiggingCalculationsProvider } from './digging/DiggingCalculationsProvider';
import { useSubscribedQuery } from '../../../hooks/useSubscribedQuery';
import EditSessions from './EditSessions';
import ThreeDimensionalShip from './3danim/ThreeDimensionalShip';

export const GQL_CONTAINER_FRAGMENT = gql`fragment ContainerView on Container {
	id
	equipmentIdentifier,
	isEmpty
	carrierReference,
	attachedEquipment,
	grossWeight,
	isoCode,
	portOfLoading,
	portOfDischarge,
	grossWeight,
	isVgm,
	reeferTemperature,
	isOog,
	overLengthFront,
	overLengthBack,
	overWidthLeft,
	overWidthRight,
	overHeight,
	dangerousGoods {
		imoClass,
		undgCode,
	},
	length,
	width,
	height
	stackLocation,
	visibility
}`;

export default function ViewCall() {
	const { id } = useParams();
	const user = useContext(UserContext);

	const componentToPrint = useRef();
	const debounceRefresh = useRef(null);

	const [ selectedBay, setSelectedBay ] = useState(null);
	const [ inOutBound, setInOutBound ] = useState('OUTBOUND');

	const [ tab, setTab ] = useState('BAYS');
	const [ printMode, setPrintMode ] = useState({ render: false, selectedOnly: false, overviewOnly: false });
	const [ highlightCell, setHighlightCell ] = useState(null);

	// Coloring mode
	const [ colorMode, setColorMode ] = usePersistentState('PORT', 'whydah.coloring'); // either PORT, SPECIALS, or OPERATIONAL_STATUS

	// View 20ft and 40ft in the same bay
	const [ viewSettingsModalOpen, setViewSettingsModalOpen ] = useState(false);

	const [ combinedBays, setCombinedBays ] = usePersistentState(true, 'combinedBays');
	const [ filterPolOnly, setFilterPolOnly ] = usePersistentState(false, 'filterPolOnly');
	const [ showSwapSuggestions, setShowSwapSuggestions ] = usePersistentState(true, 'showSwapSuggestions');
	const [ splitBaysOnPrintIfWide, setSplitBaysOnPrintIfWide ] = usePersistentState(false, 'splitBaysOnPrintIfWide');
	const [ realisticContainerHeights, setRealisticContainerHeights ] = usePersistentState(true, 'realisticContainerHeights');
	const [ dontShowNumbersOfEmptiesToBeLoaded, setDontShowNumbersOfEmptiesToBeLoaded ] = usePersistentState(false, 'dontShowNumbersOfEmptiesToBeLoaded');
	const [ betterHighlightForPolOnly, setBetterHighlightForPolOnly ] = usePersistentState(false, 'betterHighlightForPolOnly');

	// Modals
	const [ importBaplieModalOpen, setImportBaplieModalOpen ] = useState(false);
	const [ exportBaplieModalOpen, setExportBaplieModalOpen ] = useState(false);
	const [ editCallInfoModalOpen, setEditCallInfoModalOpen ] = useState(false);
	const [ warningModal, setWarningModalOpen ] = useState(false);
	const [ cargoListOpen, setCargoListOpen ] = useState(false);
	const [ mergeModalOpen, setMergeModalOpen ] = useState(false);

	const { data, loading, refetch } = useQuery(gql`
       ${GQL_CONTAINER_FRAGMENT}
        
		query Query($id: Int!) {
	        stowagePlan(id: $id) {
	            id,
	            callReference,
	            shipName,
	            pta,
	            ptd,
	            portUnlo,
		        operationalPlan {
			        id,
                }
		        outboundCells {
                    id,
                    bay, row, tier,
                    cellCode,
                    container {
                        ...ContainerView
                    }
                }
		        inboundCells {
                    id,
                    bay, row, tier,
                    cellCode,
                    container {
                        ...ContainerView
                    }
                }
		        shipTemplate {
			        id,
			        templateName,
			        imoCode,
			        cells {
				        id,
				        bay, row, tier,
				        hasReeferPlug,
				        forceTwentyFeet,
				        cellCode
                    }
                }
	        }
		}`, { variables: { id } });

	const sp = data?.stowagePlan;

	const operationalPlanSubscription = useSubscription(gql`subscription Subscription($opPlanId: Int!) {
		subscribeToOperationalPlan(operationalPlanId: $opPlanId)
	}`, { variables: { opPlanId: sp?.operationalPlan?.id }, skip: sp?.operationalPlan?.id == null });
	const operationalPlanQuery = useSubscribedQuery(useQuery(gql`query Query($opPlanId: Int!) {
		operationalPlan(id: $opPlanId) {
			id,
			shipPosition,
			bays {
			    id,
			    bayNumber,
			    operationType,
			    baySection,
			    crane,
			    orderForCrane,
			}
        }
	}`, { variables: { opPlanId: sp?.operationalPlan?.id }, skip: sp?.operationalPlan?.id == null }), operationalPlanSubscription);
	const operationalPlan = operationalPlanQuery?.data?.operationalPlan;

	// Get voyage data within plan
	const intermanVoyageData = useQuery(gql`query Query($id: Int!) {
		intermanVoyageDataForStowagePlan(stowagePlanId: $id)
	}`, { variables: { id }, pollInterval: 3 * 60 * 1000 });
	const voyageData = useMemo(() => {
		try {
			if (intermanVoyageData?.data?.intermanVoyageDataForStowagePlan == null) return { inbound: {}, outbound: {} };
			return JSON.parse(intermanVoyageData?.data?.intermanVoyageDataForStowagePlan);
		} catch (e) {
			console.error(e);
			return { inbound: {}, outbound: {} };
		}
	}, [ intermanVoyageData?.data ]);

	// Get container digging information
	const containerDiggingQuery = useQuery(gql`{
		containerDiggingMap {
			containerNumber,
			blocking
        }
	}`, { pollInterval: 60 * 1000 });
	const containerDiggingMap = useMemo(() => (containerDiggingQuery?.data?.containerDiggingMap ?? []).reduce((tot, elem) => ({ ...tot, [elem.containerNumber]: elem }), {}), [ containerDiggingQuery.data ]);

	const subscription = useSubscription(gql`
        ${GQL_CONTAINER_FRAGMENT}
		
		subscription Subscription($id: Int!) {
            subscribeToCellUpdatesWithinPlan(planId: $id) {
	            cells {
                    id,
                    bay, row, tier,
                    cellCode,
                    container {
                        ...ContainerView
                    }
                }
            }
        }
	`, {
		variables: { id },
		onData: ({ data }) => {
			const cells = data?.data.subscribeToCellUpdatesWithinPlan?.cells || [];
			let invalidated = false;
			const knownCells = new Set(sp.outboundCells.filter(oc => oc.container != null).map(c => c.id));
			cells.forEach(cell => {
				if (!knownCells.has(cell.id)) invalidated = true;
			});
			if (invalidated) {
				console.log('Invalidated, refetching');
				refetch();
			}
		},
	});

	const hasNoShipTemplate = sp?.shipTemplate == null || sp?.shipTemplate.cells.length == 0;
	const hasNoCells = sp?.inboundCells.length == 0 && sp?.outboundCells.length == 0;

	const [ swapOutboundCells, swapOutboundCellsMutation ] = useMutation(gql`
        ${GQL_CONTAINER_FRAGMENT}
		mutation Mutation($planId: Int!, $cellCodeFrom: String!, $cellCodeTo: String!) {
		    swapOutboundCells(planId: $planId, cellCodeFrom: $cellCodeFrom, cellCodeTo: $cellCodeTo) {
		        id,
		        bay, row, tier
		        container {
			        ...ContainerView
		        }
		    }
		}
	`);

	const [ setContainerVisibility, setContainerVisibilityMutation ] = useMutation(gql`
        ${GQL_CONTAINER_FRAGMENT}
        mutation Mutation($containerId: Int!, $visibility: Visibility!) {
            setContainerVisibility(containerId: $containerId, visibility: $visibility) {
                ...ContainerView,
            }
        }
	`);

	const hasTemplate = sp?.shipTemplate != null;
	const templateCells = useMemo(() => cellCodeMapLookup(sp?.shipTemplate?.cells ?? []), [ sp?.shipTemplate?.cells ]);
	// const bayBounds = useMemo(() => calculateBayBounds(sp?.shipTemplate?.cells ?? []), [ sp?.shipTemplate?.cells ]);
	const cells = (inOutBound == 'INBOUND' ? sp?.inboundCells : sp?.outboundCells) ?? [];
	const cellsByCode = useMemo(() => cellCodeMapLookup(cells), [ cells ]);
	const polFilter = filterPolOnly ? sp?.portUnlo : null;
	const baysNonCollapsed = useMemo(() => cellsToBays(cells, sp?.shipTemplate?.cells, false), [ cells, sp?.shipTemplate?.cells ]);
	const inboundCells = useMemo(() => cellsToBays(sp?.inboundCells ?? [], sp?.shipTemplate?.cells, false), [ cells, sp?.shipTemplate?.cells ]);
	const outboundCells = useMemo(() => cellsToBays(sp?.outboundCells ?? [], sp?.shipTemplate?.cells, false), [ cells, sp?.shipTemplate?.cells ]);
	const bays = useMemo(() => cellsToBays(cells, sp?.shipTemplate?.cells, combinedBays), [ cells, combinedBays, sp?.shipTemplate?.cells ]);
	const killedSlots = useMemo(() => calculateKilledCells(bays), [ bays ]);
	const shifters = useMemo(() => calculateShifterSet(sp?.inboundCells || [], sp?.outboundCells || []), [ sp ]);

	const warningList = useMemo(() => {
		// always pass it only the outbound cells because giving warnings on incoming is useless
		return generateWarnings(sp?.outboundCells, sp?.shipTemplate, killedSlots);
	}, [ sp?.outboundCells, sp?.shipTemplate, killedSlots ]);

	const cellsById = useMemo(() => {
		const output = {};
		(sp?.inboundCells ?? []).forEach(c => (output[c.id] = c));
		(sp?.outboundCells ?? []).forEach(c => (output[c.id] = c));
		return output;
	}, [ sp?.inboundCells, sp?.outboundCells ]);

	useEffect(() => {
		if (selectedBay == null) return;
		if (bays[selectedBay] == null) setSelectedBay(null);
	}, [ selectedBay, bays ]);

	const ports = useMemo(() => {
		const portSet = new Set();
		for (let i = 0; i < cells.length; i++) {
			const cell = cells[i];
			if (cell == null || cell.container == null) continue;
			portSet.add(cell.container.portOfDischarge);
		}
		return portSet;
	}, [ cells ]);

	const doHighlightCell = useCallback(cellCode => {
		if (cellCode != null) {
			const targetBay = parseInt(cellCode.slice(0, 3), 10);
			setSelectedBay(combinedBays && targetBay % 2 == 0 ? targetBay - 1 : targetBay);
		}
		setHighlightCell(cellCode);
	}, [ setHighlightCell, setSelectedBay, combinedBays ]);

	return <DiggingCalculationsProvider stowagePlan={sp} operationalPlan={operationalPlan} containerDiggingMap={containerDiggingMap}>
		{sp == null && loading && <LoadingOverlay />}
		{sp != null && <>
			<ViewCallSettingsModal
				isOpen={viewSettingsModalOpen}
				combinedBays={combinedBays}
				setCombinedBays={setCombinedBays}
				filterPolOnly={filterPolOnly}
				setFilterPolOnly={setFilterPolOnly}
				showSwapSuggestions={showSwapSuggestions}
				setShowSwapSuggestions={setShowSwapSuggestions}
				splitBaysOnPrintIfWide={splitBaysOnPrintIfWide}
				setSplitBaysOnPrintIfWide={setSplitBaysOnPrintIfWide}
				realisticContainerHeights={realisticContainerHeights}
				setRealisticContainerHeights={setRealisticContainerHeights}
				dontShowNumbersOfEmptiesToBeLoaded={dontShowNumbersOfEmptiesToBeLoaded}
				setDontShowNumbersOfEmptiesToBeLoaded={setDontShowNumbersOfEmptiesToBeLoaded}
				betterHighlightForPolOnly={betterHighlightForPolOnly}
				setBetterHighlightForPolOnly={setBetterHighlightForPolOnly}
				onClose={() => setViewSettingsModalOpen(false)} />

			<ImportBaplieModal
				planId={sp.id}
				portUnlo={sp.portUnlo}
				isOpen={importBaplieModalOpen}
				onClose={() => {
					refetch();
					setImportBaplieModalOpen(false);
				}}
				hasContainersInbound={sp?.inboundCells.filter(c => c.container != null).length != 0}
				hasContainersOutbound={sp?.outboundCells.filter(c => c.container != null).length != 0} />

			<ExportBaplieModal
				sp={sp}
				isOpen={exportBaplieModalOpen}
				onClose={() => {
					refetch();
					setExportBaplieModalOpen(false);
				}} />

			<EditCallInfoModal
				sp={sp}
				isOpen={editCallInfoModalOpen}
				onClose={() => {
					refetch();
					setEditCallInfoModalOpen(false);
				}} />

			<WarningModal
				isOpen={warningModal}
				onClose={() => setWarningModalOpen(false)}
				doHighlightCell={doHighlightCell}
				warningList={warningList} />

			<MergeModal
				sp={sp}
				isOpen={mergeModalOpen}
				onClose={() => {
					setMergeModalOpen(false);
					refetch();
				}} />

			<div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100%' }}>
				<div className="toolbar" style={{ background: '#f0f0f0' }}>
					<div className="toolbar-description">
						<div style={{ display: 'flex', flexDirection: 'row', gap: 'var(--u-8)', alignItems: 'center' }}>
							<div>
								<Button onClick={() => setEditCallInfoModalOpen(true)} data-tip data-for='edit'>
									<span className="fa fa-pencil" />
								</Button>
								<ReactTooltip id="edit" place='bottom' effect='solid' data-offset="{'bottom': 10, 'right': 10}">Aanpassen</ReactTooltip>
							</div>
							<div>
								<span style={{ color: 'var(--col-grey-700)', fontSize: 'var(--fs-9)', textTransform: 'uppercase' }}>{sp.callReference} - {sp.shipName} - {sp.portUnlo}</span>
							</div>
							<div>
								<LinkButton to={"/operationalplan/stowplan/" + id}>Ops Plan</LinkButton>
							</div>
						</div>
					</div>
					<div className="toolbar-controls">
						<div style={{ display: 'flex', flexDirection: 'row', gap: 'var(--u-8)', alignItems: 'center' }}>
							<div className="button-group">
								 <Button data-tip data-for='bay' active={tab == 'BAYS'} level={tab == 'BAYS' ? 'primary' : 'secondary'} onClick={() => setTab('BAYS')}><span className="fa fa-table" /></Button>
								 <ReactTooltip id="bay" place='top' effect='solid'>Bay details</ReactTooltip>
								 <Button data-tip data-for='threed-view' active={tab == 'SHIP_3D'} level={tab == 'SHIP_3D' ? 'primary' : 'secondary'} onClick={() => setTab('SHIP_3D')}><span className="fa fa-cube" /></Button>
								 <ReactTooltip id="threed-view" place='top' effect='solid'>3D-weergave</ReactTooltip>
								 <Button data-tip data-for='bays-overview' active={tab == 'BAYS_OVERVIEW'} level={tab == 'BAYS_OVERVIEW' ? 'primary' : 'secondary'} onClick={() => setTab('BAYS_OVERVIEW')}><span className="fa fa-bar-chart" /></Button>
								 <ReactTooltip id="bays-overview" place='top' effect='solid'>Overzicht bays</ReactTooltip>
								 <Button data-tip data-for='track-vessel' active={tab == 'VESSEL_TRACKER'} level={tab == 'VESSEL_TRACKER' ? 'primary' : 'secondary'} onClick={() => setTab('VESSEL_TRACKER')} disabled={sp?.shipTemplate?.imoCode == null}><span className="fa fa-map-o" /></Button>
								 <ReactTooltip id="track-vessel" place='top' effect='solid'>Schip volgen</ReactTooltip>
							</div>
							<div className="toolbar-separator" />
							<div className="button-group">
								<Button level={inOutBound == 'INBOUND' ? 'primary' : 'secondary'} active={inOutBound == 'INBOUND'} data-tip data-for='inboundbutton' onClick={() => setInOutBound('INBOUND')}><span className="fa fa-arrow-circle-down" />&nbsp;&nbsp;In</Button>
								<ReactTooltip id="inboundbutton" place='top' effect='solid' >Inkomend stuwplan</ReactTooltip>
								<ReactTooltip id="outboundbutton" place='top' effect='solid' >Uitgaand stuwplan</ReactTooltip>
								<Button level={inOutBound == 'OUTBOUND' ? 'primary' : 'secondary'} active={inOutBound == 'OUTBOUND'} data-tip data-for='outboundbutton' onClick={() => setInOutBound('OUTBOUND')}>
									<span className="fa fa-arrow-circle-up" />&nbsp;&nbsp;Uit
								</Button>
							</div>
							<Button level={cargoListOpen ? 'primary' : 'secondary'} active={cargoListOpen} data-tip data-for='cargolist' onClick={() => setCargoListOpen(cl => !cl)}><span className="fa fa-list"/>&nbsp;&nbsp; Containers</Button>
							<ReactTooltip id="cargolist" place='top' effect='solid' >Containerlijst</ReactTooltip>

							<div className="toolbar-separator" />

							<Button onClick={() => setImportBaplieModalOpen(true)}><span className="fa fa-cloud-upload" />&nbsp;&nbsp;Importeer BAPLIE</Button>
							<Button onClick={() => setExportBaplieModalOpen(true)}><span className="fa fa-cloud-download" />&nbsp;&nbsp;Exporteer BAPLIE</Button>
							<Button onClick={() => setMergeModalOpen(true)}><span className="fa fa-fast-forward" />&nbsp;&nbsp;Merge</Button>
							<Button onClick={() => setWarningModalOpen(true)}><span className="fa fa-exclamation-triangle " /> {warningList.length} </Button>

							<div className="toolbar-separator" />

							<div className="button-group">
								<ReactToPrint
									onBeforeGetContent={() => {
										setPrintMode({ render: true, selectedOnly: false });
										return new Promise(resolve => setTimeout(resolve, 500));
									}}
									onAfterPrint={() => setPrintMode({ render: false, selectedOnly: false })}
									trigger={() => <Button data-tip data-for='print'>
										&nbsp;
										<span className="fa fa-print" />
										<span style={{ overflow: 'visible', width: '8px', height: '1px', position: 'relative' }}>
											<span style={{ position: 'absolute', color: 'white', background: 'var(--col-grey-700)', borderRadius: '50%', display: 'inline-block', width: '12px', height: '12px', fontSize: '11px', lineHeight: '11px', border: '1px solid var(--col-grey-100)', top: '2px', left: '-6px' }}>&infin;</span>
										</span>
									</Button>}
									content={() => componentToPrint?.current} />
								<ReactTooltip id="print" place='bottom' effect='solid' >Volledig plan afdrukken</ReactTooltip>

								<ReactToPrint
									onBeforeGetContent={() => {
										setPrintMode({ render: true, selectedOnly: true });
										return new Promise(resolve => setTimeout(resolve, 50));
									}}
									onAfterPrint={() => setPrintMode({ render: false, selectedOnly: true })}
									trigger={() => <Button disabled={selectedBay == null} data-tip data-for='print-single'>
										&nbsp;
										<span className="fa fa-print" />
										<span style={{ overflow: 'visible', width: '8px', height: '1px', position: 'relative' }}>
											<span style={{ position: 'absolute', color: 'white', background: 'var(--col-grey-700)', borderRadius: '50%', display: 'inline-block', width: '12px', height: '12px', fontSize: '8px', lineHeight: '11px', border: '1px solid var(--col-grey-100)', top: '2px', left: '-6px' }}>1</span>
										</span>
									</Button>}
									content={() => componentToPrint?.current} />
								<ReactTooltip id="print-single" place='bottom' effect='solid' >Huidige bay afdrukken</ReactTooltip>

								<ReactToPrint
									onBeforeGetContent={() => {
										setPrintMode({ render: true, selectedOnly: false, overviewOnly: true });
										return new Promise(resolve => setTimeout(resolve, 50));
									}}
									onAfterPrint={() => setPrintMode({ render: false, selectedOnly: false, overviewOnly: false })}
									trigger={() => <Button data-tip data-for='print-overview'>
										&nbsp;
										<span className="fa fa-print" />
										<span style={{ overflow: 'visible', width: '8px', height: '1px', position: 'relative' }}>
											<span style={{ position: 'absolute', color: 'white', background: 'var(--col-grey-700)', borderRadius: '50%', display: 'inline-block', width: '12px', height: '12px', fontSize: '7px', lineHeight: '11px', border: '1px solid var(--col-grey-100)', top: '2px', left: '-6px' }}><span className="fa fa-th-large" /></span>
										</span>
									</Button>}
									content={() => componentToPrint?.current} />
								<ReactTooltip id="print-overview" place='bottom' effect='solid' >Alleen overzicht van bays afdrukken</ReactTooltip>
							</div>
							<div className="toolbar-separator" />
							<div className="button-group">
								<Button onClick={() => setColorMode('PORT')} level={colorMode == 'PORT' ? 'primary' : 'secondary'} active={colorMode == 'PORT'}><span className="fa fa-anchor" /></Button>
								<Button onClick={() => setColorMode('OPERATIONAL_STATUS')} level={colorMode == 'OPERATIONAL_STATUS' ? 'primary' : 'secondary'} active={colorMode == 'OPERATIONAL_STATUS'}><span className="fa fa-tasks" /></Button>
								<Button onClick={() => setColorMode('SPECIALS')} level={colorMode == 'SPECIALS' ? 'primary' : 'secondary'} active={colorMode == 'SPECIALS'}><span className="fa fa-snowflake-o" /></Button>
								<Button onClick={() => setColorMode('DIGGING')} level={colorMode == 'DIGGING' ? 'primary' : 'secondary'} active={colorMode == 'DIGGING'}><span className="fa fa-sort-numeric-desc" /></Button>
							</div>
							<div className="toolbar-separator" />
							<Button onClick={() => setViewSettingsModalOpen(true)} data-tip data-for='settings'><span className="fa fa-cogs" /></Button>
							<ReactTooltip id="settings" place='left' effect='solid' >Instellingen</ReactTooltip>
						</div>
					</div>
				</div>

				{/* Empty state messages for when a call has no template AND no cells, it has inbound cells to display but not outbound, it has outbound cells but not inbound */}
				{hasNoShipTemplate && hasNoCells && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '22%', fontSize: '150%' }}>
					<span className="fa fa-ship" /> <span>Importeer een BAPLIE en voeg een scheepssjabloon toe of gegevens te laten verschijnen.</span>
				</div>}
				{hasNoShipTemplate && sp?.outboundCells.length != 0 && (inOutBound == 'INBOUND' && sp?.inboundCells.length == 0) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '22%', fontSize: '150%' }}>
					<span className="fa fa-ship" /> <span>Importeer een inkomende BAPLIE om gegevens te laten zien.</span>
				</div>}
				{hasNoShipTemplate && sp?.inboundCells.length != 0 && (inOutBound == 'OUTBOUND' && sp?.outboundCells.length == 0) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '22%', fontSize: '150%' }}>
					<span className="fa fa-ship" /> <span>Importeer een uitgaande BAPLIE om gegevens te tonen.</span>
				</div>}

				{cargoListOpen && <CargoList
					shifters={shifters}
					highlightCell={highlightCell}
					setHighlightCell={doHighlightCell}
					editable={inOutBound == 'OUTBOUND'}
					inOutBound={inOutBound}
					plan={sp}
					polFilter={polFilter}
					refetch={refetch}
					cells={cells}
					open={cargoListOpen}
					onClose={() => setCargoListOpen(false)} />}

				{(!hasNoShipTemplate || !hasNoCells) && <div style={{ display: 'grid', gridTemplateColumns: (user.email.includes('@atrocit.com') ? '360px 1fr 200px' : '360px 1fr'), height: '100%', overflow: 'hidden' }}>
					<div style={{ display: 'flex', flexDirection: 'column', height: '100%', borderRight: (bays.length !== 0) ? '1px solid var(--col-grey-200)' : 'none', overflowY: 'auto' }}>
						<div className="bays" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', overflowY: 'auto', padding: 'var(--u-8)' }}>
							{bays.map((bay, index) => {
								return <SelectableMiniBay
									key={index}
									voyageData={voyageData}
									colorMode={colorMode}
									showSwapSuggestions={showSwapSuggestions}
									cellsByCode={cellsByCode}
									shifters={shifters}
									highlightCell={highlightCell}
									hasTemplate={hasTemplate}
									templateCells={templateCells}
									selectedBay={selectedBay}
									bay={bay}
									inOutBound={inOutBound}
									polFilter={polFilter}
									betterHighlightForPolOnly={betterHighlightForPolOnly}
									bayNumber={index}
									onSelect={() => setSelectedBay(index)}
									baysNonCollapsed={baysNonCollapsed}
									combinedBays={combinedBays}
									containerDiggingMap={containerDiggingMap}
									blockedCells={bays ? killedSlots : []} />;
							})}
						</div>
					</div>

					{tab == 'BAYS' && <div style={{ padding: 'var(--u-16)', overflow: 'auto', display: 'flex' }}>
						{selectedBay != null && bays[selectedBay] != null && <div style={{ margin: 'auto' }}>
							<div style={{ textAlign: 'center', fontSize: '20pt', paddingLeft: 'var(--u-16)' }}>
								Bay {selectedBay} {combinedBays && baysNonCollapsed[selectedBay + 1] != null && <span>+ {selectedBay + 1}</span>}
								<br /><br />
							</div>
							<BigBay
								voyageData={voyageData}
								colorMode={colorMode}
								showSwapSuggestions={showSwapSuggestions}
								onToggleVisibility={(containerId, visibility) => setContainerVisibility({ variables: { containerId, visibility } })}
								combinedBays={combinedBays}
								shifters={shifters}
								setHighlightCell={doHighlightCell}
								highlightCell={highlightCell}
								hasTemplate={hasTemplate}
								cellsByCode={cellsByCode}
								templateCells={templateCells}
								inOutBound={inOutBound}
								bay={bays[selectedBay]}
								bayNumber={selectedBay}
								polFilter={polFilter}
								containerDiggingMap={containerDiggingMap}
								blockedCells={bays ? killedSlots : []}
								realisticContainerHeights={realisticContainerHeights}
								dontShowNumbersOfEmptiesToBeLoaded={dontShowNumbersOfEmptiesToBeLoaded}
								swapOutboundCells={(cellCodeFrom, cellCodeTo) => {
									swapOutboundCells({ variables: { planId: id, cellCodeFrom, cellCodeTo } });// .finally(refetch);
								}}
								portUnlo={sp.portUnlo}/>
							<div style={{ paddingTop: 'var(--u-16)' }}>
								<ColorsLegend ports={ports} colorMode={colorMode} inOutBound={inOutBound} />
							</div>
						</div>}
					</div>}
					{tab == 'SHIP_3D' && <ThreeDimensionalShip voyageData={voyageData} plan={sp} selectedBay={selectedBay} polFilter={polFilter} inOutBound={inOutBound} />}
					{tab == 'BAYS_OVERVIEW' && (bays.length !== 0) && <BaysOverview bays={bays} inboundCells={inboundCells} outboundCells={outboundCells} baysNonCollapsed={baysNonCollapsed} polFilter={polFilter} />}
					<div style={tab == 'VESSEL_TRACKER' ? null : { display: 'block', position: 'fixed', top: '-300px', left: '-200px', width: '140px', height: '100px' }}>
						{sp?.shipTemplate?.imoCode != null && <VesselFinderView imo={sp?.shipTemplate?.imoCode} />}
					</div>

					{user.email.includes('@atrocit.com') && <div className="other-column" style={{ borderLeft: '1px solid var(--col-grey-200)', height: '100%', overflow: 'auto', right: 0, position: 'fixed', display: 'flex', flexDirection: 'column' }}>
						<EventLog stowagePlanId={id} cellsById={cellsById} />
						<div style={{ flex: 1 }} />

						{!window.location.host.includes('localhost') && <div style={{ position: 'sticky', bottom: 0, background: 'repeating-linear-gradient(110deg, #fa8 0px, #fa8 10px, #fba 10px, #fba 20px)', padding: '8px', borderTop: '#f80 solid 2px', color: '#fff', fontWeight: 'bold', fontSize: '9pt', textShadow: ' 0 1px 2px rgba(0, 0, 0, 0.6)', textAlign: 'center' }}>
							PRODUCTION
						</div>}
					</div>}

					<div style={{ position: 'fixed', bottom: 0, right: 0, paddingRight: 'var(--u-16)', paddingBottom: 'var(--u-16)' }}>
						<EditSessions id={id} />

						<div style={{ width: '48px' }}>
							{(loading || swapOutboundCellsMutation.loading) && <LoadingIndicator />}
						</div>
					</div>
				</div>}
				{printMode?.render && <BaysPrintView
					ref={componentToPrint}
					voyageData={voyageData}
					colorMode={colorMode}
					splitBaysOnPrintIfWide={splitBaysOnPrintIfWide}
					overviewOnly={printMode?.overviewOnly}
					selectedBayOnly={printMode?.selectedOnly ? selectedBay : null}
					call={sp}
					ports={ports}
					shifters={shifters}
					hasTemplate={hasTemplate}
					templateCells={templateCells}
					inOutBound={inOutBound}
					combinedBays={combinedBays}
					nonCollapsedBays={baysNonCollapsed}
					bays={bays}
					containerDiggingMap={containerDiggingMap}
					dontShowNumbersOfEmptiesToBeLoaded={dontShowNumbersOfEmptiesToBeLoaded}
					polFilter={polFilter}
					betterHighlightForPolOnly={betterHighlightForPolOnly} />}
			</div>
		</>}
	</DiggingCalculationsProvider>;
}

function calculateOffsets(rowNumber) {
	let offsetLeft, offsetRight = 0;
	// if the container is in the middle of the ship then the left and right rows of it will be +2 and -1 respectively
	if (rowNumber == 0) {
		offsetLeft = 2;
		offsetRight = -1;
		// if the container is on the left side of the ship then we have even numbers for the rows
	} else if (rowNumber % 2 == 0) {
		offsetLeft = 2;
		offsetRight = 2;
	} else {
		// otherwise we are on the right side of the ship with odd row numbers
		// offsetRight is -2 here because we do a general operation of row - offsetRight, we turn the operation into row + offsetRight in this way
		offsetLeft = -2;
		offsetRight = -2;
	}
	return [ offsetLeft, offsetRight ];
}

function equipmentIdCellMap(cells) {
	return cells.map(c => [ c.container, c.cellCode ])
		.filter(([ c ]) => c != null && c.equipmentIdentifier != null && c.equipmentIdentifier.length > 0)
		.map(([ c, cellCode ]) => [ prepContainerNumber(c.equipmentIdentifier), cellCode ])
		.reduce((map, [ cn, cellCode ]) => ({ ...map, [cn]: cellCode }), {});
}

function equipmentIdEquipmentMap(cells) {
	return cells.map(c => [ c.container, c.container ])
		.filter(([ c ]) => c != null && c.equipmentIdentifier != null && c.equipmentIdentifier.length > 0)
		.map(([ c, cont ]) => [ prepContainerNumber(c.equipmentIdentifier), cont ])
		.reduce((map, [ cn, cont ]) => ({ ...map, [cn]: cont }), {});
}

function calculateShifterSet(inboundCells, outboundCells) {
	const inboundContainerMap = equipmentIdCellMap(inboundCells);
	const outboundContainerMap = equipmentIdCellMap(outboundCells);
	const inboundContainerCMap = equipmentIdEquipmentMap(inboundCells);
	const outboundContainerCMap = equipmentIdEquipmentMap(outboundCells);

	return new Set([ ...Object.keys(outboundContainerMap), ...Object.keys(inboundContainerMap) ].filter(cn => {
		return inboundContainerCMap[cn]?.visibility == 'VISIBLE' || outboundContainerCMap[cn]?.visibility == 'VISIBLE' || (outboundContainerMap[cn] != null && inboundContainerMap[cn] != null && outboundContainerMap[cn] != inboundContainerMap[cn]);
	}));
}

function calculateKilledCells(bays) {
	// set containing the cell codes of the affected cells
	const killedCells = new Set([]);
	let [ offsetTop, offsetLeft, offsetRight ] = Array(3).fill(2);
	// loop through each bay
	for (let i = 0; i < bays.length; i++) {
		const bay = bays[i];
		// some bays do not exist
		if (bay != null) {
			// loop through all the cells of each bay
			for (let j = 0; j < bay.cells.length; j++) {
				const cell = bay.cells[j];
				// a cell being null means that its one of the empty cells on the bay (no containers on it)
				if (cell != null && cell.container != null) {
					[ offsetLeft, offsetRight ] = calculateOffsets(cell.row);
					// adds the cell code of the cell above the container
					if (cell.container.overHeight != null) {
						if (cell.container.isoCode.charAt(0) == '4') {
							killedCells.add(toCellCode(cell.bay, cell.row, cell.tier + offsetTop));
							killedCells.add(toCellCode(cell.bay + 1, cell.row, cell.tier + offsetTop));
							killedCells.add(toCellCode(cell.bay - 1, cell.row, cell.tier + offsetTop));
						} else {
							killedCells.add(toCellCode(cell.bay, cell.row, cell.tier + offsetTop));
						}
					}
					// adds the cell codes to the left of the container
					if (cell.container.overWidthLeft != null) {
						if (cell.container.isoCode.charAt(0) == '4') {
							// if the cell row is 1, then we can't substract -2 cause that would end up in cell row -1 which is not a valid position
							// so we set it to 0 if that scenario happens
							killedCells.add(toCellCode(cell.bay, cell.row + offsetLeft < 0 ? 0 : cell.row + offsetLeft, cell.tier));
							killedCells.add(toCellCode(cell.bay + 1, cell.row + offsetLeft < 0 ? 0 : cell.row + offsetLeft, cell.tier));
							killedCells.add(toCellCode(cell.bay - 1, cell.row + offsetLeft < 0 ? 0 : cell.row + offsetLeft, cell.tier));
						} else {
							killedCells.add(toCellCode(cell.bay, cell.row + offsetLeft < 0 ? 0 : cell.row + offsetLeft, cell.tier));
						}
					}
					// adds the cell codes to the right of the container
					if (cell.container.overWidthRight != null) {
						if (cell.container.isoCode.charAt(0) == '4') {
							killedCells.add(toCellCode(cell.bay, cell.row - offsetRight, cell.tier));
							killedCells.add(toCellCode(cell.bay + 1, cell.row - offsetRight, cell.tier));
							killedCells.add(toCellCode(cell.bay - 1, cell.row - offsetRight, cell.tier));
						} else {
							killedCells.add(toCellCode(cell.bay, cell.row - offsetRight, cell.tier));
						}
					}
					// adds the cell codes of the cells in front and back of the container
					if (cell.container.overLengthFront != null) {
						killedCells.add(toCellCode(cell.bay - 1, cell.row, cell.tier));
					}
					if (cell.container.overLengthBack != null) {
						killedCells.add(toCellCode(cell.bay + 1, cell.row, cell.tier));
					}
				}
			}
		}
	}
	return killedCells;
}

function addToBays(bayNr, combinedBays) {
	if (combinedBays) {
		if (bayNr % 2 == 0) {
			return [ bayNr - 1, bayNr + 1 ];
		} else {
			return [ bayNr ];
		}
	} else {
		return [ bayNr ];
	}
}

export function cellCodeMapLookup(cellList) {
	const lookup = {};
	for (let i = 0; i < cellList.length; i++) {
		lookup[cellList[i].cellCode] = cellList[i];
	}
	return lookup;
}

export function cellsToBays(cells, templateCells, combinedBays = false) {
	const bays = [];

	for (let i = 0; i < cells.length; i++) {
		const cell = cells[i];
		if (cell.container == null) continue;

		const baysToAdd = addToBays(cell.bay, combinedBays);
		baysToAdd.forEach(bay => {
			if (bays[bay] == null) {
				bays[bay] = {
					cells: [],
					rows: new Set(),
					tiers: new Set(),
				};
			}
			bays[bay].cells.push(cell);
			bays[bay].rows.add(cell.row);
			bays[bay].tiers.add(cell.tier);
		});
	}

	// Skip the rest of the bay construction if no template known
	if (templateCells == null) return bays;

	// Determine the bounds of each bay to render
	for (let i = 0; i < templateCells.length; i++) {
		const cell = templateCells[i];
		const baysToAdd = addToBays(cell.bay, combinedBays);
		baysToAdd.forEach(bay => {
			if (bays[bay] == null) {
				bays[bay] = {
					cells: [],
					rows: new Set(),
					tiers: new Set(),
				};
			}
			bays[bay].rows.add(cell.row);
			bays[bay].tiers.add(cell.tier);
		});
	}

	return bays;
}